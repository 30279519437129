/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import AOS from 'aos';
import focusLock from 'dom-focus-lock';

// Animate On Scroll
AOS.init({
	once: true,
	easing: 'ease'
});


(function () {

	'use strict';

	// Breakpoint where swiper will be destroyed
	const breakpoint = window.matchMedia('(min-width:40em)');

	// Keep track of swiper instances to destroy later
	let mySwiper;

	const breakpointChecker = function () {

		// if larger viewport and multi-row layout needed
		if (breakpoint.matches === true) {

			// clean up old instances and inline styles when available
			if (mySwiper !== undefined) mySwiper.destroy(true, true);

			// or/and do nothing
			return;

			// else if a small viewport and single column layout needed
		} else if (breakpoint.matches === false) {

			// fire small viewport version of swiper
			return enableSwiper();
		}
	};

	const enableSwiper = function () {
		var galleryBlocks = document.querySelectorAll('.gallery-block');
		if (galleryBlocks.length) {
			(async () => {
				const Swiper = (await import('swiper')).default;

				galleryBlocks.forEach(el => {
					mySwiper = new Swiper(el.querySelector('.swiper-container'), {
						slidesPerView: 'auto',
						watchOverflow: true,
						keyboard: {
							enabled: true
						},
						pagination: {
							el: el.querySelector('.swiper-pagination'),
							type: 'bullets',
							clickable: true
						}
					});
				});
			})();
		}
	};

	// keep an eye on viewport size changes
	breakpoint.addListener(breakpointChecker);

	// kickstart
	breakpointChecker();
})();

// Calculate 
var timeout = false,
	timeoutDelay = 250;

function calculateSizes() {
	var uspTicker = document.getElementById('usp-ticker');
	if (uspTicker) {
		document.documentElement.style.setProperty('--pt', `${uspTicker.clientHeight}px`);
	}
}

calculateSizes();

window.addEventListener('resize', function () {
	clearTimeout(timeout);
	timeout = setTimeout(calculateSizes, timeoutDelay);
});

//// Remove iframe from iOS and replace with button
//function insertAfter(referenceNode, newNode) {
//	referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
//}

//var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
//if (isIOS) {
//	var iframeWrapper = document.getElementById('signup-iframe-wrapper'),
//		iframe = iframeWrapper.getElementsByTagName('iframe')[0],
//		src = iframe.src,
//		title = iframe.title;

//	// Add button
//	var el = document.createElement("a");
//	el.className = "button large arrow block-button";
//	el.href = src;
//	el.innerHTML = title;
//	insertAfter(iframeWrapper, el);

//	// Destroy iframe
//	iframeWrapper.parentNode.removeChild(iframeWrapper);

//	// Remove text block so button is shown earlier
//	var content = document.getElementById('content');
//	const elements = content.getElementsByClassName('text-block');
//	while (elements.length > 0) {
//		elements[0].parentNode.removeChild(elements[0]);
//	}
//}

// Swiper
var sliders = document.querySelectorAll('.slider');
if (sliders.length) {
	(async () => {
		//const Swiper = (await import('swiper')).default;
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;

		sliders.forEach(el => {
			new Swiper(el.querySelector('.swiper-container'), {
				slidesPerView: 'auto',
				watchOverflow: true,
				keyboard: {
					enabled: true
				},
				navigation: {
					nextEl: el.querySelector('.swiper-button-next'),
					prevEl: el.querySelector('.swiper-button-prev')
				},
				pagination: {
					el: el.querySelector('.swiper-pagination'),
					type: 'bullets',
					clickable: true
				}
			});
		});
	})();
}

var uspTickers = document.querySelectorAll('.usp-ticker');
if (uspTickers.length) {
	(async () => {
		//const Swiper = (await import('swiper')).default;
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;

		uspTickers.forEach(el => {
			new Swiper(el.querySelector('.swiper-container'), {
				slidesPerView: '1',
				autoplay: {
					delay: 4000
				},
				loop: true,
				speed: 1500
			});
		});
	})();
}

// Lightgallery
async function loadLightGallery() {
	await import(/* webpackChunkName: "lightgallery" */ 'lightgallery.js');
	await import(/* webpackChunkName: "lightgallery" */ 'lg-video.js');

	lightGallery(document.getElementsByTagName("body")[0], {
		selector: '[rel=modal]',
		download: false,
		thumbnail: false,
		fullScreen: true,
		actualSize: false,
		hash: false,
		videoMaxWidth: '1920px'
	});
}

var elementsWithModal = document.querySelectorAll('[rel=modal]');
if (elementsWithModal.length) {
	loadLightGallery();
}

// Make toggles keyboard accessible
document.querySelectorAll('label[role="button"]').forEach(toggle => {
	toggle.addEventListener('keydown', e => {
		// Space and Enter
		if (e.keyCode === 13 || e.keyCode === 32) {
			toggle.click();
			e.preventDefault();
		}
	});

	toggle.setAttribute('tabindex', 0);
});

// Close navigation on backdrop click or escape key
const navigationToggler = document.getElementById('nav'), navigationWrapper = document.querySelector('header .nav-wrapper'), navigation = document.querySelector('header .nav-wrapper nav');
if (navigationToggler && navigationWrapper && navigation) {
	var closeToggle = navigation.querySelector('.close');
	const toggle = checked => {
		navigationToggler.checked = checked;
		navigationWrapper.setAttribute('aria-expanded', checked);

		if (checked) {
			focusLock.on(navigation);

			// Focus close toggle on open
			if (closeToggle) {
				closeToggle.focus();
			}
		} else {
			focusLock.off(navigation);
		}
	};

	navigationWrapper.addEventListener('click', e => {
		if (!navigation.contains(e.target)) {
			toggle(false);
		}
	});

	document.addEventListener('keydown', e => {
		if (e.keyCode === 27 && navigationToggler.checked) {
			toggle(false);
		}
	});

	// Keep aria-expanded in-sync with checked state
	navigationToggler.addEventListener('change', () => {
		toggle(navigationToggler.checked);
	});

	toggle(navigationToggler.checked);
}

// Close dropdown on click
document.querySelectorAll('dl.languages > dt, dl.dropdown-list > dt, dl.anchor-list > dt').forEach(el => {
	el.addEventListener('click', () => {
		el.parentElement.classList.add('clicked');
		el.parentElement.classList.toggle('open');
	});

	el.addEventListener('blur', () => {
		// Timeout is required for iOS
		setTimeout(() => {
			el.parentElement.classList.remove('clicked');
			el.parentElement.classList.remove('open');
		}, 0);
	});

	// Prevent mousedown in next sibling (dd) to prevent click event to bubble to blur
	el.nextElementSibling.addEventListener('mousedown', e => {
		e.preventDefault();
	});

	el.addEventListener('keydown', e => {
		// Space and Enter
		if (e.keyCode === 13 || e.keyCode === 32) {
			el.click();
			e.preventDefault();
		}
	});
});
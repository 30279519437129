/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import { Swiper, Navigation, Pagination, Keyboard, EffectFade, Autoplay } from 'swiper';

Swiper.use([Navigation, Pagination, Keyboard, EffectFade, Autoplay]);

export default Swiper;


